import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  ShouldRevalidateFunction,
  useLoaderData,
  useLocation,
} from "@remix-run/react";
import "./index.css";
import { LoaderFunctionArgs, MetaFunction, json } from "@remix-run/node";
import { checkAuth, commitSession } from "./session.server";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import posthog from "posthog-js";
import { SentryMetaArgs } from "@sentry/remix";
import { Button } from "./components/Button";
import { useExpoToken } from "./routes/expo/useExpoToken";
import { User } from "./models/User";
import { usePushNotificationTabbed } from "./routes/expo/usePushNotificationTabbed";
import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { AdaptyProfile } from "react-native-adapty";

export const meta = ({ data }: SentryMetaArgs<MetaFunction<typeof loader>>) => {
  return [
    { name: "sentry-trace", content: data.sentryTrace },
    { name: "baggage", content: data.sentryBaggage },
  ];
};

export const shouldRevalidate: ShouldRevalidateFunction = ({ formAction }) => !!formAction;

export async function loader({ request }: LoaderFunctionArgs) {
  const { session, user } = await checkAuth(request);
  const error = session.get("error");
  const errorAt = session.get("errorAt");
  const msg = session.get("msg");
  const msgAt = session.get("msgAt");
  const msgAction = session.get("msgAction");
  const headers = { "Set-Cookie": await commitSession(session) };
  const _user = await User.findOne({ _id: user?._id });
  const notificationAllow = _user?.notificationAllow;

  return json(
    {
      user,
      notificationAllow,
      error,
      errorAt,
      msg,
      msgAction,
      msgAt,
      ENV: {
        SENTRY_DSN: process.env.SENTRY_DSN,
        ENVIRONMENT: process.env.ENVIRONMENT,
        POST_HOG_TOKEN: process.env.POST_HOG_TOKEN,
      },
    },
    { headers }
  );
}

export const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

export { ErrorBoundary } from "~/components/RemixErrorBoundary";

declare global {
  interface Window {
    ENV: {
      SENTRY_DSN: string;
      ENVIRONMENT: "Local" | "Staging" | "Production";
      POST_HOG_TOKEN: string;
    };
    ReactNativeWebView?: {
      postMessage: (data: string) => void;
    };
    receiveExpoDeviceToken?: (token: string) => void;
    receivePushNotification?: (alertId: string) => void;
    adaptyProfileUpdated?: (profile: AdaptyProfile) => void;
  }
}

export default function App() {
  const { user, notificationAllow, error, errorAt, msg, msgAction, msgAt, ENV } =
    useLoaderData<typeof loader>();

  useEffect(() => {
    if (error === "__plan_exceeded__")
      toast.warn(
        <div className="grid grid-cols-3 gap-1">
          <div className="col-span-2">You've reached your limit! Upgrade to get full access.</div>
          <Link to="/boards/settings/plans" className="mr-1">
            <Button>Upgrade</Button>
          </Link>
        </div>,
        {
          theme: "dark",
          autoClose: 6000,
        }
      );
    else
      toast.error(error, {
        theme: "dark",
        autoClose: 3000,
        closeOnClick: true,
        hideProgressBar: true,
      });
  }, [error, errorAt]);

  useEffect(() => {
    if (msg)
      toast.success(
        <div className="flex items-center justify-between">
          <div>{msg}</div>

          {msgAction && (
            <Button variant="outline">{<Link to={msgAction}>Go to column</Link>}</Button>
          )}
        </div>,
        { theme: "dark", autoClose: 3000, closeOnClick: true, hideProgressBar: true }
      );
  }, [msg, msgAction, msgAt]);

  const location = useLocation();
  useEffect(() => {
    posthog.capture("$pageview");
  }, [location]);

  useEffect(() => {
    if (notificationAllow?.mobile) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ type: "permission_granted" }));
    }
  }, [notificationAllow]);
  useExpoToken({ userId: user?._id });
  usePushNotificationTabbed();

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        {/* <meta name="viewport" content="width=device-width, initial-scale=1" /> */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0, viewport-fit=cover"
        />
        <Meta />
        <link rel="manifest" href="/manifest.json" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <Links />
      </head>
      <body>
        <MobileAppProvider>
          <QueryClientProvider client={queryClient}>
            <Outlet />
            <script dangerouslySetInnerHTML={{ __html: `window.ENV = ${JSON.stringify(ENV)}` }} />
          </QueryClientProvider>
        </MobileAppProvider>
        <ScrollRestoration />
        <Scripts />
        <ToastContainer />
      </body>
    </html>
  );
}

const MobileAppContext = createContext(
  {} as {
    isMobileApp: boolean;
  }
);

export const MobileAppProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [isMobileApp, setIsMobileApp] = useState(false);

  useEffect(() => {
    setIsMobileApp(!!window.ReactNativeWebView);
  }, []);

  return <MobileAppContext.Provider value={{ isMobileApp }}>{children}</MobileAppContext.Provider>;
};

export const useMobileApp = () => {
  const { isMobileApp } = useContext(MobileAppContext);
  return { isMobileApp };
};
