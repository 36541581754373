import { useNavigate, useParams } from "@remix-run/react";
import { useEffect } from "react";

export const usePushNotificationTabbed = () => {
  const navigate = useNavigate();
  const boardId = useParams().boardId;
  useEffect(() => {
    window.receivePushNotification = (alertId?: string) => {
      console.log(`alertId: ${alertId}`);
      navigate(`/boards/m/alerts/${boardId}`);
    };

    return () => {
      window.receivePushNotification = undefined;
    };
  }, []);
};
