import { useFetcher } from "@remix-run/react";
import { useEffect, useState } from "react";

export const useExpoToken = ({ userId }: { userId?: string }) => {
  const [expoDeviceToken, setExpoDeviceToken] = useState<string>();

  useEffect(() => {
    //@ts-ignore
    window.receiveExpoDeviceToken = (token: string) => {
      setExpoDeviceToken(token);
      localStorage.set("__expoDeviceToken__", token);
    };
    return () => {
      //@ts-ignore
      window.receiveExpoDeviceToken = undefined;
    };
  }, [setExpoDeviceToken]);

  const fetcher = useFetcher<any>();

  useEffect(() => {
    if (expoDeviceToken && userId) {
      fetcher.submit(
        { expoDeviceToken },
        {
          method: "POST",
          action: "/expo",
          encType: "application/json",
        }
      );
    }
  }, [expoDeviceToken, userId]);
};
